import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
import Features from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature from "components/features/TwoColWithButton.js";
import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
import Contact from "components/forms/SimpleContactUs.js";
import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";
import Footer from "components/footers/FiveColumnDark.js";
import serverRedundancyIllustrationImageSrc from "images/server-redundancy-illustration.svg";
import serverSecureIllustrationImageSrc from "images/server-secure-illustration.svg";

import CookieConsent, { Cookies } from "react-cookie-consent";

export default () => {
  return (
    <AnimationRevealPage>
      <Hero />
      <Features />
      <Pricing />
      {/* <MainFeature 
        subheading="Reliable"
        heading="Highly Redundant Servers With Backup"
        imageSrc={serverRedundancyIllustrationImageSrc}
        buttonRounded={false}
      />
      <MainFeature 
        subheading="Secure"
        heading="State of the Art Computer Security"
        imageSrc={serverSecureIllustrationImageSrc}
        buttonRounded={false}
        textOnLeft={false}
      /> */}
      {/* <Testimonial /> */}
      <FAQ />
      <Contact />
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="Aceitar Cookies"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        Este site armazena cookies em seu computador, que serão usados para
        coletar informações sobre como você interage com nosso site e nos
        permite lembrar de você. Usamos essas informações para melhorar e
        personalizar sua experiência. Para obter mais informações,{" "}
        <a href="#">leia nossa Política de Privacidade</a>.
      </CookieConsent>
    </AnimationRevealPage>
  );
};
